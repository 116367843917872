import { yupResolver } from "@hookform/resolvers/yup";
import { useForm as useFormHookForm } from "react-hook-form";

/*
 * Note: when you using handleSubmit method, and
 * you need handle exceptions in the callback
 * passed to handleSubmit, please throw an error if you need api validations
 */
export const useForm = ({ schema, ...props } = {}) => {
  const formMethods = useFormHookForm({
    resolver: schema ? yupResolver(schema) : undefined,
    mode: "onChange",
    ...props
  });

  const { handleSubmit, setError } = formMethods;

  const onValidHandler = async (onValid, data) => {
    try {
      await onValid(data);
    } catch (e) {
      const backendErrors = e?.response?.data?.errors;

      const errors = Object.entries(
        typeof backendErrors === "string" ? {} : backendErrors ?? {}
      );

      errors.forEach(([errorName, errs]) => {
        setError(errorName, {
          type: "custom",
          message:
            typeof errs === "string"
              ? errs
              : errs.message ?? "Este campo está incorrecto"
        });
      });
    }
  };

  return {
    ...formMethods,
    handleSubmit: (onValid, onInvalid) =>
      handleSubmit(data => onValidHandler(onValid, data), onInvalid)
  };
};
