import { useController } from "react-hook-form";
import React from "react";
import { Div, Icon, Input, Text } from "atomize";

const InputField = ({
  nameField,
  control,
  rules,
  isRequired = false,
  variant = "input",
  selectOpts,
  children,
  label,
  _container,
  ...props
}) => {
  const { placeholder } = props;
  const {
    field,
    fieldState: { invalid, error, isTouched },
  } = useController({
    name: nameField,
    control,
    rules: {
      required: isRequired
        ? {
            value: true,
            message: `El campo ${`${
              placeholder ?? label
            }`.toLocaleLowerCase()} es requerido`,
          }
        : false,
      ...rules,
    },
  });

  if (variant === "input-unstyled") {
    return (
      <>
        <input type="text" {...field} {...props} id={nameField} />

        {invalid ? (
          <p style={{ fontSize: ".8rem", color: "red" }}>
            {error?.message ?? "error"}
          </p>
        ) : null}
      </>
    );
  }

  return (
    <Div
      className="form-group"
      m={{ b: "1rem" }}
      p={{ x: "1rem" }}
      {..._container}
    >
      <label htmlFor={nameField}>{label}</label>
      <div className="p-relative">
        {typeof children === "function" ? (
          children(field, props)
        ) : (
          <>
            {variant === "textarea" ? (
              <textarea
                row="3"
                cols="50"
                type="text"
                {...field}
                {...props}
                id={nameField}
              ></textarea>
            ) : null}

            {variant === "input" || !variant ? (
              <Input
                type="text"
                rounded="circle"
                borderColor="gray400"
                focusBorderColor="info700"
                style={{ borderRadius: 9999, borderColor: "#cbd5e1" }}
                suffix={
                  <Icon
                    pos="absolute"
                    name="Rename"
                    color="light"
                    size="18px"
                    top="50%"
                    transform="translateY(-50%)"
                    right="1rem"
                  />
                }
                {...field}
                {...props}
                id={nameField}
              />
            ) : null}
          </>
        )}
      </div>
      {invalid ? (
        <Text textSize="tiny" textColor="danger700">
          {error?.message ?? "error"}
        </Text>
      ) : null}
    </Div>
  );
};

export default InputField;
