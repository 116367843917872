import * as yup from "yup";

export const emailSchema = yup
  .string()
  .email("Debe ser un email valido")
  .required("El email es requerido");
export const passwordSchema = yup
  .string()
  .required("La contraseña es requerida");

export const confirmPasswordSchema = (field = "password") =>
  yup
    .string()
    .oneOf([yup.ref(field), null], "Las contraseñas no coinciden")
    .required("La contraseña de confirmacion es requerida");

export const loginSchema = yup.object({}).shape({
  email: emailSchema,
  password: passwordSchema,
});

export const addShipmentEventSchema = yup.object({}).shape({
  event: yup.string().required("El evento es requerido"),
});

const register = {
  email: emailSchema,
  password: passwordSchema,
  confirm_password: confirmPasswordSchema("password"),
  name: yup.string().required("El nombre es requerido"),
  lastname: yup.string().required("El apellido es requerido"),
  phone: yup.string().required("El teléfono es requerido"),
};

export const registerUsersSchema = yup.object({}).shape({
  ...register,
});

export const loginUsersSchema = yup.object({}).shape({
  email: emailSchema,
  password: passwordSchema,
});
